<template>
  <base-layout-two page-title="New Resource" page-default-back-link="/resources">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Resource Details">
          <ion-grid>
            <ion-row>
              <!-- Name -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Name *">
                  <v-field name="name" v-slot="{ field }" v-model="resource.name" :rules="required">
                    <ion-input name="name" v-bind="field" type="text" autocorrect></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- URL -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="URL *">
                  <v-field name="url" v-slot="{ field }" v-model="resource.url" :rules="required">
                    <ion-input name="url" v-bind="field" type="text"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="url" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Location -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Location *">
                  <v-field name="location" v-slot="{ field }" v-model="resource.location" :rules="required">
                    <ion-input name="location" v-bind="field" type="text"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="location" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Category -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Category *">
                  <v-field name="category" v-slot="{ field }" v-model="resource.category" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="Acupuncture">Acupuncture</option>
                      <option value="Child Care/Postpartum Doulas">Child Care/Postpartum Doulas</option>
                      <option value="Chiropractor">Chiropractor</option>
                      <option value="Cleaning">Cleaning</option>
                      <option value="Emotional/Mental Health">Emotional/Mental Health</option>
                      <option value="Lactation">Lactation</option>
                      <option value="Massage Therapy">Massage Therapy</option>
                      <option value="Meal Delivery">Meal Delivery</option>
                      <option value="Nutrition">Nutrition</option>
                      <option value="Pediatrician">Pediatrician</option>
                      <option value="Physical Therapist">Physical Therapist</option>
                      <option value="Products">Products</option>
                      <option value="Return to Exercise">Return to Exercise</option>
                      <option value="Sleep Consultants">Sleep Consultants</option>
                    </select>
                  </v-field>
                </base-input>
              </ion-col>

              <!-- Excerpt -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Excerpt *">
                  <v-field name="description_short" v-slot="{ field }" v-model="resource.description_short">
                    <ion-textarea name="description_short" :auto-grow="true" rows="1" v-bind="field" placeholder=""
                      autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="description_short" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Description -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Description *">
                  <v-field name="description" v-slot="{ field }" v-model="resource.description" :rules="required">
                    <ion-textarea name="description" :auto-grow="true" rows="2" v-bind="field" placeholder=""
                      autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="description" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonTextarea, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      resource: {},
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/resources`,
        data: this.resource,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.resource = response.data.resource;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Resource Created", color: "secondary" });

          this.$router.replace(`/resources/${this.resource.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>